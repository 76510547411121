/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Introducing the Arc Sport: A High-Performance Electric Sports Boat"), "\n", React.createElement(_components.h2, null, "Overview"), "\n", React.createElement(_components.p, null, "According to The Verge, electric boat startup Arc has expanded its lineup with a new plug-in sports boat called the Arc Sport. The Arc Sport is a battery-powered wake sports boat that offers high performance. It is equipped with 226kWh of battery capacity and a 570-horsepower motor, providing more than double the torque of most premium wake boats. The boat features advanced software and wireless updates, allowing for continuous improvement over time. The starting price for the Arc Sport is $258,000."), "\n", React.createElement(_components.p, null, "Source: ", React.createElement(_components.a, {
    href: "https://www.theverge.com/2024/2/20/24075155/arc-sport-electric-wake-sport-boat-specs-price"
  }, "Electric boat startup Arc expands lineup with new plug-in sports boat - The Verge")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
